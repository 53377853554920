import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { signInWithEmailAndPassword } from "firebase/auth";
import { FormEvent, useState } from "react";
import { auth } from "../../../firebase";
import "./AuthenticationDialog.scss";
import AuthToast from "./AuthToast";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSignupClick: () => void;
};

const SigninDialog = ({ open, handleClose, handleSignupClick }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toastOpen, setToastOpen] = useState(false);

  const onLogin = (e: FormEvent) => {
    handleClose();
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setToastOpen(true);
        const user = userCredential.user;
        console.log("signed in with user: " + user);
      })
      .catch(err => alert(err.message))
  };
  return (
    <>
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Sign in</Dialog.Title>
          <Dialog.Description className="DialogDescription">
            {/* long description here describing sign in*/}
            Use your email and password to sign in. Don't have an account?{" "}
            <button
              onClick={handleSignupClick}
              style={{ cursor: "pointer" }}
              className="text-[#ffffff] underline"
            >
              Sign up
            </button>
          </Dialog.Description>
          <form onSubmit={onLogin}>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="name">
                Email
              </label>

              <input
                onChange={(x) => setEmail(x.target.value)}
                className="Input"
                id="email"
                placeholder="example@gmail.com"
              />
            </fieldset>
            <fieldset className="Fieldset">
              <label className="Label" htmlFor="password">
                Password
              </label>
              <input
                onChange={(x) => setPassword(x.target.value)}
                className="Input"
                id="password"
                placeholder="••••••••"
              />
            </fieldset>
          </form>
          <div
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            <Dialog.Close asChild>
              <div className="Button-green-border">
                <button onClick={onLogin} className="Button green">
                  Continue
                </button>
              </div>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              onClick={handleClose}
              className="IconButton"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
    <AuthToast type="success" action="login" open={toastOpen} setOpen={setToastOpen} email={email}/>
    </>
  );
};
export default SigninDialog;
