import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { AuthContextValue, AuthProvider } from "../AuthContext";
import AuthToast from "../components/dialogs/AuthDialog/AuthToast";
import SigninDialog from "../components/dialogs/AuthDialog/SigninDialog";
import SignupDialog from "../components/dialogs/AuthDialog/SignupDialog";
import Notes from "../components/Notes";
import { auth } from "../firebase";
import base_styles from "./page.module.css";

const NOTES_API_ENDPOINT = "https://notes-api-9qu8.onrender.com" as string;

type Props = {
  initialNotes: { id: string; content: string }[];
};

const Home = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState<
    AuthContextValue | User | undefined
  >(undefined);
  const [toastOpen, setToastOpen] = useState(false);

  // firebase auth
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setCurrentUser(user);
        setLoggedIn(true);
        const uid = user.uid;
        console.log("uid: ", uid);

        // fetch notes from api
        try {
          const response = await fetch(`${NOTES_API_ENDPOINT}/notes/${uid}`);
          const data = await response.json();
          console.log(data);
          setInitialNotes(data);
        } catch (err) {
          console.log(err);
        }
      } else {
        // User is signed out
        console.log("user is logged out");
      }
    });
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setCurrentUser(undefined);
        setLoggedIn(false);
        setToastOpen(true);
        // clears notes
        console.log("Signed out successfully");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [initialNotes, setInitialNotes] = useState<Props["initialNotes"]>([]);
  const [showSigninDialog, setShowsigninDialog] = useState(false);
  const [showSignupDialog, setShowsignupDialog] = useState(false);

  const handleSignInClick = () => {
    setShowsigninDialog(true);
  };

  const handleSignInClose = () => {
    setShowsigninDialog(false);
  };

  const handleSignUpClick = () => {
    setShowsignupDialog(true);
    setShowsigninDialog(false);
  };

  const handleBackToSigninClick = () => {
    setShowsigninDialog(true);
    setShowsignupDialog(false);
  };

  const handleSignUpClose = () => {
    setShowsignupDialog(false);
  };

  return (
    <AuthProvider value={currentUser}>
      <>
        <main className={base_styles.main}>
          <div
            id="appbar"
            style={{ display: "flex", position: 'static', top: 0, left: 0, width: "100%", marginBottom: "1rem" }}
          >
            <h1 className="text-[4em]">Notes</h1>
            <div className="flex flex-grow-[1] items-center justify-end gap-3">
              {/* using tailwindcss create a modern sign in button */}
              <div className="backlight-border">
                {loggedIn === false ? (
                  <button
                    onClick={handleSignInClick}
                    className="backlight bg-[#1f2935] text-white hover:text-[#1f2935] rounded-xl px-4 py-2 cursor-pointer"
                  >
                    Sign in
                  </button>
                ) : (
                  <button
                    onClick={handleLogout}
                    className="backlight bg-[#1f2935] text-white hover:text-[#1f2935] rounded-xl px-4 py-2 cursor-pointer"
                  >
                    Logout
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className={ loggedIn === true ? "note_container w-full h-full" : "flex w-full h-full"}>
            {
              // if logged in, show notes
              loggedIn === true ? (
              <Notes initialNotes={initialNotes} />
              ) : (
                <div className="flex flex-col items-center justify-center h-[80vh] w-full">
                  <h1 className="text-4xl font-bold">Welcome to Notes</h1>
                  <p className="text-xl mt-4">
                    Sign in or create an account to start taking notes
                  </p>
                </div>
              )
            }
          </div>
          <AuthToast action="logout" open={toastOpen} setOpen={setToastOpen} type="success" email=""/>
        </main>

        <SigninDialog
          open={showSigninDialog}
          handleClose={handleSignInClose}
          handleSignupClick={handleSignUpClick}
        />
        <SignupDialog
          open={showSignupDialog}
          handleClose={handleSignUpClose}
          handleSigninClick={handleBackToSigninClick}
        />
      </>
    </AuthProvider>
  );
};

export default Home;
