import { User } from 'firebase/auth';
import React, { useContext } from 'react'

export interface AuthContextValue {
    user: User;
    setUser: (user: any) => void;
}

const AuthContext = React.createContext<AuthContextValue | User | undefined>(undefined)

type AuthProviderProps = {
    children: React.ReactNode,
    value: AuthContextValue | User | undefined
}

export function AuthProvider({ children, value }: AuthProviderProps) {
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuthValue() {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuthValue must be used within an AuthProvider')
    }
    return context
}
