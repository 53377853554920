import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth } from "../../../firebase";
import "./AuthenticationDialog.scss";
import AuthToast from "./AuthToast";

const NOTES_API_ENDPOINT = "https://notes-api-9qu8.onrender.com";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSigninClick: () => void;
};

const SignupDialog = ({ open, handleClose, handleSigninClick }: Props) => {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toastOpen, setToastOpen] = useState(false);

  const register = (e: any) => {
    console.log("register");
    handleClose();
    e.preventDefault();
    setError("");
    console.error("error: " + error);
    // Create a new user with email and password using firebase
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        const user = res.user;
        console.log(username + JSON.stringify(user));
        fetch(`${NOTES_API_ENDPOINT}/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: user.uid,
          }),
        });
        setToastOpen(true);

      })
      .catch((err) => setError(err.message));
  };

  return (
    <>
      <Dialog.Root open={open}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Dialog.Title className="DialogTitle">Sign Up</Dialog.Title>
            <Dialog.Description className="DialogDescription">
              Create an account to get started. Already have an account?{" "}
              <button
                onClick={handleSigninClick}
                style={{ cursor: "pointer" }}
                className="text-[#ffffff] underline"
              >
                Sign in
              </button>
            </Dialog.Description>
            <form>
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="username">
                  Username
                </label>

                <input
                  onChange={(x) => setUsername(x.target.value)}
                  className="Input"
                  id="name"
                  placeholder="johnsmith"
                />
              </fieldset>
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="email">
                  Email
                </label>

                <input
                  onChange={(x) => setEmail(x.target.value)}
                  className="Input"
                  id="email"
                  placeholder="example@gmail.com"
                />
              </fieldset>
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="password">
                  Password
                </label>
                <input
                  onChange={(x) => setPassword(x.target.value)}
                  className="Input"
                  id="password"
                  placeholder="••••••••"
                />
              </fieldset>
            </form>
            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild>
                <div className="Button-green-border">
                  <button
                    type="submit"
                    onClick={register}
                    className="Button green"
                  >
                    Continue
                  </button>
                </div>
              </Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button
                type="submit"
                onClick={handleClose}
                className="IconButton"
                aria-label="Close"
              >
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <AuthToast type="success" action="signup" open={toastOpen} setOpen={setToastOpen} email={email} />
    </>
  );
};
export default SignupDialog;
