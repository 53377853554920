import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import "../app/globals.scss";
import { auth } from "../firebase";
import CreateNote from "./CreateNote";
import Note from "./Note";

const NOTES_API_ENDPOINT = "https://notes-api-9qu8.onrender.com" as string;

const Notes = ({
  initialNotes,
}: {
  initialNotes: { id: string; content: string }[];
}) => {
  const [notes, setNotes] = useState(initialNotes);
  const [inputText, setInputText] = useState("");
  const uid = auth.currentUser?.uid;

  useEffect(() => {
    if (uid) {
      const fetchNotes = async () => {
        try {
          const response = await fetch(
            `${NOTES_API_ENDPOINT}/notes/${uid}`
          );
          const data = await response.json();

          setNotes(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchNotes();
    }
  }, [uid]);

  const textHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const saveHandler = async () => {
    const noteId = uuid();
    const note = {
      id: noteId,
      userId: uid,
      content: inputText,
    };

    const response = await fetch(`${NOTES_API_ENDPOINT}/users`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: uid,
        notes: [...notes, note],
      }),
    });

    await response.json();

    setNotes([...notes, note]);
    setInputText("");
  };

  // delete note from user notes using "DELETE" method
  const deleteNote = async (id: string) => {
    const response = await fetch(
      `${NOTES_API_ENDPOINT}/users/${uid}/notes/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    await response.json();

    setNotes(notes.filter((note) => note.id !== id));
  };

  return (
    <>
      {notes &&
        notes.map((note: { id: string; content: string }) => (
          <Note
            key={note.id}
            id={note.id}
            text={note.content}
            deleteNote={deleteNote}
          />
        ))}

      <CreateNote
        textHandler={textHandler}
        saveHandler={saveHandler}
        inputText={inputText}
      />
    </>
  );
};

export default Notes;
