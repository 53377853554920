"use client";
const CreateNote = ({ textHandler, saveHandler, inputText }: { textHandler: (e: any) => void, saveHandler: () => void, inputText: string }) => {
  return (
    <div
      className="note w-[28rem] h-64"
      style={{ background: "rgba(255, 255, 255, 0.1)" }}
    >
      <textarea
        cols={36}
        rows={5}
        placeholder="Type here..."
        value={inputText}
        maxLength={100}
        onChange={textHandler}
      ></textarea>
      <div className="note__footer">
        <button className="note__save" onClick={saveHandler}>
          SAVE
        </button>
      </div>
    </div>
  );
};

export default CreateNote;