import * as Toast from "@radix-ui/react-toast";
import "./toast.css";

type Props = {
  type: "success" | "error";
  action: "login" | "signup" | "logout";
  open: boolean;
  setOpen: (open: boolean) => void;
  email: string;
};

const AuthToast = ({ type, action, open, setOpen, email }: Props) => {
  if (open !== true) return null;
  if (open === true) {
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  }
  return (
    <Toast.Provider swipeDirection="right">
      <Toast.Root duration={3000} className="ToastRoot">
        <Toast.Title className="ToastTitle">
          {action === "login" && type === "success"
            ? "Logged in"
            : action === "signup"
            ? "Signed up"
            : action === "logout"
            ? "Logged out"
            : null}
        </Toast.Title>
        <Toast.Description asChild>
          {action === "login" && type === "success" ? (
            <p className="ToastDescription">Successfully logged into {email}</p>
          ) : action === "signup" ? (
            <p className="ToastDescription">
              Successfully signed up with {email}
            </p>
          ) : action === "logout" ? (
            <p className="ToastDescription">Successfully logged out</p>
          ) : null}
        </Toast.Description>
      </Toast.Root>
      <Toast.Viewport className="ToastViewport" />
    </Toast.Provider>
  );
};

export default AuthToast;
