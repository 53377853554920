"use client";
import { HiOutlineTrash } from "react-icons/hi";

interface INoteProps {
  id: string;
  text: string;
  deleteNote: (id: string) => void;
}

const Note = ({ id, text, deleteNote }: INoteProps) => {
  return (
    <>
      <div className="note w-[28rem] h-64">
        <textarea
          cols={36}
          rows={5}
          placeholder="Type here..."
          value={text}
          maxLength={100}
        ></textarea>
        <div className="note__footer">
          <HiOutlineTrash
            className="note__delete"
            onClick={() => deleteNote(id)}
            aria-hidden="true"
          />
        </div>
      </div>
    </>
  );
};

export default Note;
